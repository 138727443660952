import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import { graphql, Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import { LearningCenterPostBySlugQuery } from '../../graphql-types';
import renderRichBody from '../components/article/renderArticleRichText';
import ParagraphExLarge from '../ui/ParagraphExLarge';
import ImageFeature from '../components/article/ImageFeature';
import { BREAKPOINTS, COLORS } from '../ui/variables';
import Posts from '../components/learning-center/Posts';
import Heading3 from '../ui/Heading3';
import Heading1 from '../ui/Heading1';

const Container = styled.article`
  padding: 80px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled(Heading1)`
  text-align: center;
  max-width: 900px;
`;

const FeatureText = styled(ParagraphExLarge)`
  max-width: 810px;
  margin-bottom: 30px;
  width: 100%;
  text-align: center;
  margin-bottom: 40px;
`;

const RelatedWrapper = styled.div`
  background-color: ${COLORS.LIGHT_BEIGE};
`;

const Related = styled.div`
  width: 100%;
  padding: 60px 40px;
  max-width: 1000px;
  margin: auto;
`;

const Attribution = styled(ParagraphExLarge)`
  text-align: center;

  ::after {
    content: ' ';
    width: 200px;
    height: 1px;
    background: ${COLORS.LIGHT_GRAY};
    display: block;
    margin: auto;
    margin-top: 22px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    max-width: 260px;

    ::after {
      width: 140px;
    }
  }
`;

export const pageQuery = graphql`
  query LearningCenterPostBySlug($slug: String!) {
    contentfulLearningCenterArticle(urlSlug: { eq: $slug }) {
      title
      urlSlug
      seoMetaDescription
      featureText {
        featureText
      }
      body {
        raw
        references {
          ... on ContentfulArticleSingleImage {
            __typename
            contentful_id
            image {
              title
              fluid(maxWidth: 1027) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
      categories {
        urlSlug
        name
      }
      featureImage {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid_withWebp
        }
        title
      }
      relatedArticles {
        categories {
          name
          urlSlug
        }
        title
        urlSlug
        featureImage {
          title
          fluid(maxWidth: 920) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        featureText {
          featureText
        }
      }
    }
  }
`;

interface IServicePostProps {
  data: LearningCenterPostBySlugQuery;
}

const LearningCenterPost = ({ data }: IServicePostProps) => {
  const post = data.contentfulLearningCenterArticle;

  if (!post) {
    return null;
  }

  const related =
    post.relatedArticles &&
    post.relatedArticles.map(article => ({
      title: article.title,
      featureText: article.featureText.featureText,
      path: `/learning-center/${article.categories[0].urlSlug}/${article.urlSlug}`,
      featureImage: {
        fluid: article.featureImage.fluid,
        title: article.featureImage.title,
      },
      category: {
        name: article.categories[0].name,
        urlSlug: article.categories[0].urlSlug,
      },
    }));

  return (
    <Layout>
      <SEO title={post.title} description={post.seoMetaDescription} />
      <Container>
        <Title>{post.title}</Title>
        <Attribution>
          Written by{' '}
          <Link
            css={css`
              color: ${COLORS.ORANGE};
            `}
            to='/authors'
          >
            Small Door's medical experts
          </Link>
        </Attribution>
        <FeatureText>{post.featureText.featureText}</FeatureText>
        <ImageFeature
          fluidImgData={post.featureImage.fluid}
          alt={post.featureImage.title}
        />
        {post.body &&
          post.body.raw &&
          renderRichBody({
            raw: post.body.raw,
            references: post.body.references,
          })}
      </Container>
      {related && related.length > 0 && (
        <RelatedWrapper>
          <Related>
            <Heading3>Related articles</Heading3>
            <Posts posts={related} />
          </Related>
        </RelatedWrapper>
      )}
    </Layout>
  );
};

export default LearningCenterPost;
